// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A7-customer-details-popup {
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 440px;
  position: fixed;
  z-index: 99;
  top: 54%;
  left: 55%;
  transform: translate(-50%, -50%);
}
.margin184{
  margin: 7px;
}

.A7-cust-popup-h5 {
  text-align: center;
  margin-bottom: 10px;
}

.A7-cust-popup-input {
  width: 60%;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
}
.btn0011{
  border: none;
  background-color: transparent;
  font-size: 27px;
  margin-top: -15px;
}
.btn0011:hover{
  color: red;
}
.A7-cust-popup-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px ;
  cursor: pointer;
  margin:  15px;
 
}
.btn123{
  display: flex;
  justify-content: center;
}

.A7-cust-popup-button:hover {
  background-color: #0056b3;
}
.A7-cust-edit-content-popup{
  display: flex;
  flex-direction: row;
}
.A7-cust-edit-popup-close{
 position: absolute;
 right: 14px;
}
.label203{
  width: 130px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/CustomerDetailsEdit.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,wCAAwC;EACxC,aAAa;EACb,YAAY;EACZ,eAAe;EACf,WAAW;EACX,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;;AAEf;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;CACC,kBAAkB;CAClB,WAAW;AACZ;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".A7-customer-details-popup {\n  background-color: #ffffff;\n  border: 1px solid #ccc;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n  width: 440px;\n  position: fixed;\n  z-index: 99;\n  top: 54%;\n  left: 55%;\n  transform: translate(-50%, -50%);\n}\n.margin184{\n  margin: 7px;\n}\n\n.A7-cust-popup-h5 {\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.A7-cust-popup-input {\n  width: 60%;\n  border: 1px solid #ccc;\n  padding: 5px;\n  border-radius: 3px;\n}\n.btn0011{\n  border: none;\n  background-color: transparent;\n  font-size: 27px;\n  margin-top: -15px;\n}\n.btn0011:hover{\n  color: red;\n}\n.A7-cust-popup-button {\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  padding: 5px 10px ;\n  cursor: pointer;\n  margin:  15px;\n \n}\n.btn123{\n  display: flex;\n  justify-content: center;\n}\n\n.A7-cust-popup-button:hover {\n  background-color: #0056b3;\n}\n.A7-cust-edit-content-popup{\n  display: flex;\n  flex-direction: row;\n}\n.A7-cust-edit-popup-close{\n position: absolute;\n right: 14px;\n}\n.label203{\n  width: 130px;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
