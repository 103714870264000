// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.pop-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(221, 194, 194, 0.6);
  }
  
  .pop {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px 90px;
  }
  .div_3_cont_sa
  {
    display: flex;
    gap: 5vh;
  }
  .pop_inner {
    text-align: left;
  }
  .hhh_burr_sa
  {
    text-align: center;
  }
  .butt_close {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 2vh;
  } 
  
  .butt_close:hover {
    background-color: #0056b3;
  }
  
  .detail p {
    margin: 10px 0;
    font-size:2vh;  
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/styles/SalonPopup.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0CAA0C;EAC5C;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;EACpB;EACA;;IAEE,aAAa;IACb,QAAQ;EACV;EACA;IACE,gBAAgB;EAClB;EACA;;IAEE,kBAAkB;EACpB;EACA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,aAAa;IACb,4DAA4D;EAC9D","sourcesContent":["\n\n.pop-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(221, 194, 194, 0.6);\n  }\n  \n  .pop {\n    background-color: #fff;\n    border-radius: 15px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);\n    padding: 20px 90px;\n  }\n  .div_3_cont_sa\n  {\n    display: flex;\n    gap: 5vh;\n  }\n  .pop_inner {\n    text-align: left;\n  }\n  .hhh_burr_sa\n  {\n    text-align: center;\n  }\n  .butt_close {\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    cursor: pointer;\n    margin-top: 10px;\n    font-size: 2vh;\n  } \n  \n  .butt_close:hover {\n    background-color: #0056b3;\n  }\n  \n  .detail p {\n    margin: 10px 0;\n    font-size:2vh;  \n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
