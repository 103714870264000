import React from 'react';
import '../styles/Error404.css';

const Figure = () => {
  return (
    <div className='lkjhgds'>
    <div className='error_back'>
    <div className="figure">
      <div className="mj">
        <div className="head">
          <div className="nose"></div>
          <div className="hair">
            <div className="ponytail"></div>
            <div className="frontpony"></div>
          </div>
        </div>
        <div className="body">
          <div className="jacket">
            <div className="hand"></div>
          </div>
        </div>
        <div className="leg">
          <div className="foot"></div>
        </div>
        <div className="leg lft">
          <div className="foot"></div>
        </div>
      </div>
      <div className="error-no">
        <span>4</span>
        <div className="moon"></div>
        <span>4</span>       
      </div>
      <div className="error-messageghjk">Oops! The page you are looking for could not be found.</div>
    </div>
    </div>
    </div>
  );
};

export default Figure;


