// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `Terms&Conditions

.terms-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.terms-popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 950px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: Arial, sans-serif;
  outline: none; 
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 95vh;
}

.terms-popup h2 {
  margin-top: 0;
  font-size: 24px;
  color: #f37254;
  text-align: center;
}

.terms-content {
  text-align: left;
  max-height: 400px;
  overflow-y: auto;
  margin: 5px 0;
  line-height: 1.6;
}

.subscription1244 {
  font-weight: bold;
  color: #444;
}

.close-button {
  display: block;
  background-color: #f37254;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
}

.close-button:hover {
  background-color: #d9603a;
}

`, "",{"version":3,"sources":["webpack://./src/components/styles/TermsAndConditionsPopup.css"],"names":[],"mappings":"AAAA;;;EAGE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,wCAAwC;EACxC,WAAW;EACX,8BAA8B;EAC9B,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["Terms&Conditions\n\n.terms-popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.terms-popup {\n  background: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 950px;\n  width: 90%;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  color: #333;\n  font-family: Arial, sans-serif;\n  outline: none; \n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  height: 95vh;\n}\n\n.terms-popup h2 {\n  margin-top: 0;\n  font-size: 24px;\n  color: #f37254;\n  text-align: center;\n}\n\n.terms-content {\n  text-align: left;\n  max-height: 400px;\n  overflow-y: auto;\n  margin: 5px 0;\n  line-height: 1.6;\n}\n\n.subscription1244 {\n  font-weight: bold;\n  color: #444;\n}\n\n.close-button {\n  display: block;\n  background-color: #f37254;\n  color: #fff;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  border-radius: 4px;\n  text-align: center;\n  margin: 0 auto;\n}\n\n.close-button:hover {\n  background-color: #d9603a;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
