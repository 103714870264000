// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pd-table120 {
    width: 100% !important;
    border: collapse;
    margin: 0px ;
}
.pd-container12{
    /* max-height: 44vh; */
    overflow: auto;
    
    
}
.thead878{
position: sticky;
top: 0%;
z-index: 99;
}
@media (max-width: 767px) {
    .popup-content25 {
        background: #ccc;
        padding: 10px;
        border-radius: 8px;
        width: 97%;
        max-width: 100%;
        height: 99vh;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    .pp-formgroup1302 {
        display: flex;
        flex-direction: column;
        margin-bottom: 7px;
    }
    .flex004302 {
        display: flex;
        height: auto;
        justify-content: flex-start;
        gap: 0px;
        flex-direction: column;
        overflow: auto;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/PurchaseManageDealers.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,sBAAsB;IACtB,cAAc;;;AAGlB;AACA;AACA,gBAAgB;AAChB,OAAO;AACP,WAAW;AACX;AACA;IACI;QACI,gBAAgB;QAChB,aAAa;QACb,kBAAkB;QAClB,UAAU;QACV,eAAe;QACf,YAAY;QACZ,yCAAyC;IAC7C;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,kBAAkB;IACtB;IACA;QACI,aAAa;QACb,YAAY;QACZ,2BAA2B;QAC3B,QAAQ;QACR,sBAAsB;QACtB,cAAc;IAClB;;AAEJ","sourcesContent":[".pd-table120 {\n    width: 100% !important;\n    border: collapse;\n    margin: 0px ;\n}\n.pd-container12{\n    /* max-height: 44vh; */\n    overflow: auto;\n    \n    \n}\n.thead878{\nposition: sticky;\ntop: 0%;\nz-index: 99;\n}\n@media (max-width: 767px) {\n    .popup-content25 {\n        background: #ccc;\n        padding: 10px;\n        border-radius: 8px;\n        width: 97%;\n        max-width: 100%;\n        height: 99vh;\n        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    }\n    .pp-formgroup1302 {\n        display: flex;\n        flex-direction: column;\n        margin-bottom: 7px;\n    }\n    .flex004302 {\n        display: flex;\n        height: auto;\n        justify-content: flex-start;\n        gap: 0px;\n        flex-direction: column;\n        overflow: auto;\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
