// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  
  
  .heading_check {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
 
  .input_check {
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; 
    box-sizing: border-box; 
  }
  
  .butt_check_sub {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .butt_check_sub:hover {
    background-color: #0056b3;
  }
  
  .butt_check_sub12
  {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
  }
`, "",{"version":3,"sources":["webpack://./src/components/styles/ContinueSubscription.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;;;EAIA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;;EAGA;IACE,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,WAAW;EACb","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n\n  \n  \n  .heading_check {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n \n  .input_check {\n    padding: 8px;\n    margin-bottom: 12px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%; \n    box-sizing: border-box; \n  }\n  \n  .butt_check_sub {\n    padding: 8px 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 14px;\n  }\n  \n  .butt_check_sub:hover {\n    background-color: #0056b3;\n  }\n  \n  .butt_check_sub12\n  {\n    padding: 8px 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 14px;\n    width: 100%;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
